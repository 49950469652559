import React from 'react';

import SEO from '../components/molecules/seo';

const CookiesPage = () => (
  <>
    <SEO
      title="Política de cookies | JR Web"
      description="Política de privacidad y cookies relativa a los tratamientos de los datos recogidos en la web."
    />
    <section
      style={{
        margin: '4.375rem auto',
        maxWidth: '58.875rem',
      }}
    >
      <h1>Política de cookies</h1>
      <p><strong>¿Cómo utiliza la empresa titular de esta web las cookies?</strong></p>
      <p>
        La empresa titular de esta web utiliza varias tecnologías para mejorar la eficiencia
        de sus sitios web,
        incluyendo su experiencia cuando navega por dichos sitios.
        Estas tecnologías incluyen cookies.
      </p>
      <p><strong>¿Qué es una cookie?</strong></p>
      <p>
        Una cookie es un pequeño archivo, que normalmente está compuesto
        por letras y números, y que se instala en su ordenador, tableta,
        teléfono o dispositivo similar cuando lo utiliza para visitar
        un sitio web. Los propietarios de sitios web utilizan las
        cookies para que dichos sitios funcionen o para que lo hagan de
        un modo más eficiente, así como para reportar información.
      </p>
      <p><strong>¿Para qué se utilizan las cookies?</strong></p>
      <p>Hay diferentes tipos de cookies para diferentes tipos de uso.</p>
      <p>La empresa titular de esta web utiliza los siguientes tipos de cookies:</p>
      <ul>
        <li>
          Cookies estrictamente necesarias. Estas cookies son necesarias para el funcionamiento
          de nuestro sitio web.
        </li>
        <li>
          Cookies de rendimiento y analíticas. Estas cookies nos permiten reconocer y contar
          el número de visitantes en nuestro sitio web así como saber cómo se mueven por el mismo
          mientras lo utilizan. Esto nos ayuda a mejorar el funcionamiento de nuestro sitio web
          (por ejemplo, asegurándonos de que los visitantes encuentren fácilmente lo que están
          buscando).
        </li>
        <li>
          Cookies de funcionalidad. Estas cookies se utilizan para reconocerle cuando
          vuelve a visitar
          el sitio web. Esto nos permite personalizar nuestro contenido para usted, saludándole por
          su nombre y recordando sus preferencias, por ejemplo, su idioma o región.
        </li>
        <li>
          Cookies de selección. Estas cookies graban su visita al sitio web, las páginas que
          ha visitado
          y los enlaces que ha seguido. Utilizamos esta información para hacer que nuestro sitio web
          y su contenido sean más acordes a sus intereses. Esta información también es susceptible
          de ser compartida con terceros con esa finalidad.
        </li>
      </ul>
      <p>En concreto en esta web se instalan las siguientes cookies:<br /></p>
      <p>Cookies de Google Analytics, de duración indefinida</p>
      <p>_ga, _gat Finalidad: Análisis estadístico de las visitas a la web</p>
      <p>Cookie propia</p>
      <p>CookieConsent: Aceptación de cookies, de duración indefinida</p>
      <p>
        <strong>
          ¿Cuánto tiempo permanecen las cookies en mi dispositivo?
        </strong>
      </p>
      <p>
        Algunas cookies funcionan desde el momento en el que visita un sitio web de La empresa
        titular de esta web hasta el final de esa sesión de navegación en concreto. Estas
        cookies caducan y se eliminan automáticamente cuando cierra el navegador de Internet.
        Estas cookies se denominan cookies temporales o de &quot;sesión&quot;.
      </p>
      <p>
        Algunas cookies permanecerán en su dispositivo durante las sesiones de navegación,
        no caducan cuando cierra su navegador. Estas cookies se denominan cookies
        &quot;permanentes&quot;. La duración del tiempo que permanece una cookie
        permanente en su dispositivo varía de una a otra.
      </p>
      <p><strong>¿Quién instala las cookies en mi dispositivo?</strong></p>
      <p>
        La empresa titular de esta web, como operador del sitio web, instala las cookies
        en su dispositivo. Estas cookies se denominan cookies &quot;de origen&quot;. Es posible
        que otros proveedores distintos sean los que instalen cookies en su dispositivo.
        A estas cookies se las denomina cookies &quot;de terceros&quot;.
      </p>
      <p><strong>¿Cómo controlo qué cookies se instalan en mi dispositivo?</strong></p>
      <p>
        De usted depende si acepta las cookies o no. Un modo de hacerlo es a través
        de la configuración de su navegador de Internet. La mayoría de los navegadores de
        Internet permiten controlar a casi todas las cookies a través de la
        configuración de su navegador. (Observe que si utiliza la configuración de
        su navegador para bloquear todas las cookies es posible que no pueda a acceder
        a determinadas secciones de nuestro sitio web.) Los siguientes sitios web ofrecen
        información para configurar las cookies en algunos de los navegadores más conocidos:
      </p>
      <ul>
        <li><a href="http://support.apple.com/kb/PH5042">Apple Safari</a></li>
        <li><a href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647&amp;p=cpn_cookies">Google Chrome</a></li>
        <li><a href="http://www.microsoft.com/info/cookies.mspx">Microsoft Internet Explorer</a></li>
        <li><a href="http://support.mozilla.org/en-US/kb/Cookies">Mozilla Firefox</a></li>
      </ul>
      <p>
        También puede utilizar un plug-in del navegador, como
        <a href="http://www.ghostery.com/">Ghostery</a> de Evidon Inc. o
        <a href="http://tracking-protection.truste.com/">Tracking Protection List</a>
        de TRUSTe.
        (Un plug-in del navegador es una parte de un software que añade prestaciones
        extra a su navegador, por ejemplo, para ver un vídeo o buscar virus.)
      </p>
      <p>
        Para darse de baja de las cookies analíticas de Google Analytics en los sitios web de la
        empresa titular de esta web, visite:
      </p>
      <ul>
        <li>
          Google Analytics:
          <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
        </li>
      </ul>
      <p>
        Si desea obtener más información sobre anuncios de su interés y sobre sus elecciones,
        visite estos sitios:
        <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance</a>,
        <a href="http://www.networkadvertising.org/managing/opt_out.asp">Network Advertising Initiative</a> e
        <a href="http://youronlinechoices.com/">Interactive Advertising Bureau (IAB) Europe</a>.
      </p>
      <p>
        Si desea obtener más información sobre cookies en general, incluyendo el modo en el que
        las cookies se han instalado y cómo gestionarlas y eliminarlas, visite
        <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.
      </p>
      <p>
        Para ampliar esta información acuda a la siguiente página de la
        <a href="http://www.agpd.es/portalwebAGPD/index-ides-idphp.php">Agencia Española de Protección de Datos</a>
        que ayuda a configurar la privacidad en redes sociales, navegadores y
        sistemas operativos móviles. Más información.
      </p>
      <p><strong>Enlaces a otros sitios web</strong></p>
      <p>
        Si opta por abandonar nuestro sitio Web a través de enlaces a otros sitios Web
        no pertenecientes a nuestra entidad, José Antonio Rodríguez González no se hará
        responsable de las políticas de privacidad de dichos sitios Web ni de las cookies
        que estos puedan almacenar en el ordenador del usuario.
      </p>
    </section>
  </>
);

export default CookiesPage;
